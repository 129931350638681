<template>
    <div class="module-title text-white flex items-center" :style="{backgroundColor: getModuleBackground(module)}"
        :class="[ minimize ? 'i-w-40 justify-center' : 'i-w-345 justify-start px-4' ]"
    >
        <div v-if="!minimize">
            <div class="fs-28 font-bold">{{ module.title }}</div>
            <div class="fs-14 mt-2">
                {{ module.description }}
            </div>
        </div>
        <div
            v-else
            class="text-rotate-90 fs-14 font-bold"
        >
            {{ module.title }}
        </div>
    </div>
</template>

<script setup>
    import useSystemContent from './useSystemContent';
    let { getModuleBackground } = useSystemContent();

    defineProps({
        module: Object,
        minimize: Boolean
    })
</script>
<style>
.module-title {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  border-top: .5px solid #fff;
  border-bottom: .5px solid #fff;
  position: relative;
}
</style>
