<template>
    <div>
        <div @click="changeClearance()"
            class="flex flex-col gap-1 mt-2 cursor-pointer"
        >
            <BarSolidIcon 
                v-for="n in 4" 
                :key="n"
                :class="[levelClearance >= n ? 'opacity-100' : 'opacity-20']" 
            />
        </div>
        <div class="flex flex-col gap-4 mt-4">
            <div
                v-for="(item, i) in sidebarData" 
                :key="i" 
                class="w-4 h-4 rounded-sm cursor-pointer" 
                :style="{backgroundColor: item.color}"
                :class="[selectedModuleId == item.id ? 'border border-white' : '']"
                @click="$emit('onClickModule', item.id)"
            ></div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { isEmpty } from 'lodash'

    // eslint-disable-next-line no-undef
    const props = defineProps({
        sidebarData: Array,
        selectedModuleId: [Number, String],
        widget: Object
    });
    const emit = defineEmits(['onChangeLevelClearance']);

    const currentIndex = ref(0)
    const arr = [4, 3, 2, 1, 0, 1, 2, 3]

    const levelClearance = computed(() =>  arr[currentIndex.value])
    const changeClearance = () => {
        // if(!isEmpty(props.widget)) {
        //     return;
        // }
        let index = currentIndex.value;
        const nextInd = ++index % arr.length;
        currentIndex.value = nextInd;
        emit('onChangeLevelClearance', levelClearance.value);
    }

</script>