<template>
    <div
        class="title flex items-center fs-14 font-medium text-white overflow-hidden"
        :class="[contentWidth, {'hasMinimizedChild': minimize}, {'custom-height': toggleShowDescription}]"
        :style="{background: getBackground(item, module, isLastColumn)}"
        @click="toggleElement"
    >
        <div v-if="!minimize" >
            <div class="truncate" style="width: 314px">{{ item.index_code }} {{ item.title }} </div>
            <div class="tow-line-truncate fs-12" style="width: 314px" v-if="toggleShowDescription || getAtomicsCount(item) >= 3">{{ item.description }}</div>
        </div>
        <div
            v-else
            class="text-rotate-90 fs-14"
            style="line-height: 8px"
        >
            {{ truncate(item.index_code, {length: 4, omission: ''}) }}
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, set } from 'vue';
    import { truncate } from 'lodash'
    import useSystemContent from './useSystemContent';
    let { hasChild, getBackground, getAtomicsCount } = useSystemContent();
    
    const props = defineProps({
        item: Object,
        module: Object,
        isLastColumn: Boolean,
        minimize: {
            type: Boolean,
            default: () => false
        }
    });

    const contentWidth = computed(() => {
        if(hasChild(props.item)) {
            if(props.minimize) {
                return 'h-auto i-w-40 ';
            }else {
                if(props.isLastColumn) {
                    return 'i-w-345 font-semibold';
                }
                return 'h-auto i-w-345 font-semibold';
            }
        }else {
            return 'i-h-30 w-full cursor-pointer'
        }
    });
    const toggleShowDescription = ref(false);
    const toggleElement = () => {
        if(props.item.child.length > 0) {
            return;
        }
        toggleShowDescription.value = !toggleShowDescription.value;
        set(props.item, 'expandedHeight', toggleShowDescription.value ? 90 : undefined);
    }
</script>
<style scoped>
    .custom-height {
        height: 90px !important;
    }
    .title {
       border-right: 1px solid #fff;
       border-left: 1px solid #fff;
       border-top: .5px solid #fff;
       border-bottom: .5px solid #fff;
       padding-left: 15px;
       position: relative;
   }
   .hasMinimizedChild::before {
        content: '';
        position: absolute;
        left: 38px;
        width: 2px;
        background: #fff;
        height: 100%;
   }
   .tow-line-truncate {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
</style>