import BasicParameterVisualization from '@/components/roadmap/canvas/widgets/metrics/basic/Visualization.vue'
import CompareVisualization from '@/components/roadmap/canvas/widgets/metrics/compare/Visualization.vue'
import DefinitionVisualization from '@/components/roadmap/canvas/widgets/metrics/definition/Visualization.vue'
import PeopleVisualization from '@/components/roadmap/canvas/widgets/people/visualization/Index.vue'
import DynamicFieldVisualization from '@/components/roadmap/canvas/widgets/DynamicField/Visualization.vue'
import LogicBuilderVisualization from '@/components/roadmap/canvas/widgets/LogicBuilder/Visualization.vue'
import TimelineVisualization from '@/components/roadmap/canvas/widgets/timeline/Visualization.vue'
import AssociationVisualization from '@/components/roadmap/canvas/widgets/AssociatedContent/Visualization.vue'

import { getFlattenCollections } from '@/store/modules/global/services';

export default function useWidgetVisualization() {
    const widgetNames = [
        'Basic',
        'Compare',
        'Definition',
        'People',
        'Dynamic Field',
        'Logic Builder',
        'Timeline',
        'group-data',
        'Association'
    ]

    const widgets = [
        BasicParameterVisualization,
        CompareVisualization,
        DefinitionVisualization,
        PeopleVisualization,
        DynamicFieldVisualization,
        LogicBuilderVisualization,
        TimelineVisualization,
        'group-data',
        AssociationVisualization
    ]

    const getWidgetComponentByType = (type) =>  widgets[type-1]

    const getWidgetNameByType = (type) => widgetNames[type-1];

    const getFilteredCollections = module => {
        if(!module || !module.collections) {
            return [];
        }
        let collections = getFlattenCollections(module.collections);
        return collections.filter(item => item.child.length == 0);
    }
    
    return {
        getWidgetComponentByType,
        getFilteredCollections,
        getWidgetNameByType
    }
}