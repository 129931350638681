import { getFlattenCollections } from '@/store/modules/global/services';
import {has, find, cloneDeep} from 'lodash';
import store from '@/store';

export default class Collection {
    constructor(module = null) {
        this.collection = {};
        if(module) {
            this.collections = getFlattenCollections(module.collections);
            this.module = module;
        }
        this.colorCodes = store.getters['global/colorCodes'];
    }
    get = () => this.collections;
    find = (id) => find(this.collections, {id: id})
    getTitle(id, collection = null) {
        let localCollection = collection ?? (id == 'module' ? this.module : this.find(id));
        return has(localCollection, 'title') ? localCollection.title : '';
    }
    getDescription(id, collection = null) {
        let localCollection = collection ?? (id == 'module' ? this.module : this.find(id));
        return has(localCollection, 'description') ? localCollection.description : '';
    }
    getChildren(id) {
        if(id == 'module') {
            return this.module.collections;
        }
        let collection = this.find(id);
        return has(collection, 'child') ? collection.child : [];
    }

    getBackground(collection, module, colorCodes = null, orValue = null) {
        let level = collection.level > 4 ? 4 : collection.level;
        if (collection && collection.background_color) {
            return collection.background_color;
        }
        if(!module.collection_settings) {
            return '#f9f9f9';
        }
        let collectionSettings = JSON.parse(module.collection_settings);
        if (collectionSettings && collectionSettings.collections.length > 0) {
            if(collectionSettings.collections[level] && collectionSettings.collections[level].custom_bg) {
                return collectionSettings.collections[level].custom_bg;
            }
            let indexLevel = level - 1;

            colorCodes = colorCodes || this.colorCodes;
            colorCodes = colorCodes.slice(indexLevel * 9, indexLevel * 9 + 9);
            return colorCodes[collectionSettings.collections[level].color];
        }
        return orValue || '#f9f9f9';
    }

    getModuleBg(module, level = 1, colorCodes = null) {
        let collectionSettings = JSON.parse(module.collection_settings);
        if(collectionSettings && collectionSettings.collections.length > 0) {
            if(collectionSettings.collections[0].custom_bg) {
                return collectionSettings.collections[0].custom_bg;
            }
            let indexLevel = level - 1;
            let colors = colorCodes || this.colorCodes;
            let localColorCodes = colors.slice((indexLevel * 9), ((indexLevel * 9) + 9));
            return localColorCodes[collectionSettings.collections[level].color];
        }
        return null;
    }
    
    getModuleBg2(module, level = 1, colors) {
        let collectionSettings = JSON.parse(module.collection_settings);
        if(collectionSettings && collectionSettings.collections.length > 0) {
            if(collectionSettings.collections[0].custom_bg) {
                return collectionSettings.collections[0].custom_bg;
            }
            let indexLevel = level - 1;
            let colorCodes = colors.slice((indexLevel * 9), ((indexLevel * 9) + 9));
            return colorCodes[collectionSettings.collections[level].color];
        }
        return null;
    }

}
