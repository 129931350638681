<template>
    <div
        class="absolute text-primary-one -top-8 cursor-pointer items-center font-semibold"
        v-if="isVisible"
        @click="toggleClick"
        :class="{'i-ml-14' : isMinimized}"
    > {{ isMinimized ? '+' : '-' }} </div>
</template>

<script setup>
    import useSystemContent from './useSystemContent';
    let { isMinimized } = useSystemContent();
    defineProps({
        isVisible: Boolean
    });
    const emit = defineEmits(['toggle'])

    const toggleClick = () => {
        isMinimized.value = !isMinimized.value;
        emit('toggle', isMinimized);
    }
</script>
