<template>
    <div class="flex relative" :style="{minHeight: getCellHeight(module, 'collections') }">
        <!-- {{ sharedParameters }} -->
        <!-- Module column start -->
        <ToggleMinMax isVisible @toggle="value => minimization.l0 = value" />
        <ModuleContentBox :minimize="minimization.l0" :module="module" />
        <!-- Module column end -->
        <div class="flex-auto" v-if="levelClearance >= 1">
            <div class="flex relative" v-for="(l1, l1Index) in module.collections" :key="l1.id" :style="{minHeight: getCellHeight(l1) }">
                <!-- First level column start -->
                <ToggleMinMax :isVisible="l1Index == 0" @toggle="value => minimization.l1 = value" />
                <ContentBox :isLastColumn="levelClearance == 1" :minimize="minimization.l1" :module="module" :item="l1" />
                <!-- First level column end -->
                <div class="flex-auto relative" v-if="levelClearance >= 2 && l1.child.length > 0">
                    <!-- Second level column start -->
                    <ToggleMinMax :isVisible="l1Index == 0" @toggle="value => minimization.l2 = value" />
                    <div class="flex" v-for="(l2, l2Index) in l1.child" :key="l2.id" :style="{minHeight: getCellHeight(l2) }">
                        <ContentBox :isLastColumn="levelClearance == 2" :minimize="minimization.l2" :module="module" :item="l2" />
                        <!-- Second level column end -->
                        <div class="flex-auto relative" v-if="levelClearance >= 3 && l2.child.length > 0">
                            <!-- Third level column start -->
                            <ToggleMinMax :isVisible="l1Index == 0 && l2Index === 0" @toggle="value => minimization.l3 = value" />
                            <div class="flex" v-for="(l3, l3Index) in l2.child" :key="l3.id" :style="{minHeight: getCellHeight(l3) }">
                                <ContentBox :isLastColumn="levelClearance == 3" :minimize="minimization.l3" :module="module" :item="l3" />
                                <!-- Third level column end -->
                                <div class="flex-auto relative" v-if="levelClearance >= 4 && l3.child.length > 0">
                                    <!-- Fourth level column start -->
                                        <ToggleMinMax :isVisible="l1Index == 0 && l2Index === 0 && l3Index == 0" @toggle="value => minimization.l4 = value" />
                                        <div class="flex flex-col" v-for="(l4) in l3.child" :key="l4.id">
                                            <ContentBox isLastColumn :minimize="minimization.l4" :module="module" :item="l4" :class="[minimization.l4 ? 'i-w-40' : 'i-w-345' ]" />
                                        </div>
                                    <!-- Fourth level column start -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import useSystemContent from './useSystemContent';
    import ToggleMinMax from './ToggleMinMax';
    import ContentBox from './ContentBox';
    import ModuleContentBox from './ModuleContentBox'
    defineProps({
        module: Object,
        levelClearance: Number
    });
    let { minimization, getCellHeight, sharedParameters, getAtomicsCount } = useSystemContent();
</script>
