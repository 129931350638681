import { pick, cloneDeep, isArray, get } from 'lodash'
export const getSystemProperties = (parameters, system_properties) => {
    let systemProperties = [];
    parameters.forEach((parameter, index) => {

        let systemProperty = isArray(system_properties) ? system_properties.find(property => property.id === parameter.property_id) : null;
        if(systemProperty) {
            systemProperties[index] = systemProperty;
        }
        if(!systemProperty) {
          systemProperties[index] = null
        }
    });
    return systemProperties;
}

export const getProperties = (parameters, systemProperties) => {
    let properties = [];
    parameters.forEach((item, index) => {
        let systemProperty = systemProperties.find(sp => sp?.id == item.property_id)
        if(systemProperty) {
            let propertyCollection = systemProperty.property_collections.find(s => s.id == item.id);
            properties[index] = propertyCollection ? propertyCollection: []
        }
        if(!systemProperty) {
          properties[index] = [];
        }
    });
    return properties;
}

export const getParameterValue = (collection, parameter, key='properties') => {
    if ( collection && collection[key] && collection[key].length > 0 && parameter && parameter.id) {
      let param = collection[key].find((data) => data.property_collection_id === parameter.id);
      if (param) {
        return param.sample_value;
      }
      return 0;
    }
    return 0;
  }

  const _getDefinition = (input, source) => {
    let localSource = cloneDeep(source);
    let modifiedSource = localSource.map(item => {
        let arr = item.range.split('-');
        item.rangeObj = {
            start: parseInt(arr[0]),
            end: parseInt(arr[1])
        }
        return item;
    })
   let obj = modifiedSource.find((item, index, arr) => {
        let { start, end } = item.rangeObj;
        if(input > start && input < end) {
            return item;
        }
        if(input == 0) {
            return arr[0];
        }
    });
    return pick(obj, ['bg', 'definition']);
}

export const getParameterValueFromHistory = (item, parameter, parameterValues) => {
    let parameterSet = parameterValues[parameter.id];
    if(!parameterSet) {
        return 0;
    }
    let data = null;
    //have to refactor
    if(item.i_am == 'module') {
        data = parameterSet.find(i => (i.id == item.module_id && i.model == 'module'));
    }else  {
        data = parameterSet.find(i => i.id == item.id);
    }
    return data?.sample_value || 0;
}
export const setParameterValueToHistory = (item, parameter, parameterValues, value) => {
    let parameterSet = parameterValues[parameter.id];
    if(!parameterSet) {
        return
    }
    let expectedParameter = _getParameterFromParameterSet(parameterSet, item);
    console.log(expectedParameter, 'expectedParameter')
    expectedParameter.sample_value = value;
}
const _getParameterFromParameterSet = (parameterSet, item) => {
  if(item.i_am == 'module') {
    return parameterSet.find(i => (i.id == item.module_id && i.model == 'module'));
  }else  {
      return parameterSet.find(i => i.id == item.id);
  }
}
export const getDefinitionStyle = (value, serialIndex, source, total = 5) => {
    value = value > 0 ? (value - .000001) : value;
    if(total == 10) {
      return serialIndex < value ? _getDefinition(value, source) : null;
    }
    let minRange = [0, 2, 4, 6, 8];
    let valueIndex = Math.floor(value / 2);
    valueIndex =  ( valueIndex < 4 ) ? valueIndex : 4;
    if (minRange[serialIndex] < value) {
      return _getDefinition(value, source);
    }
    return null;
}

export const getDefinitionDescription = (value, source, key) => {
  value = value > 0 ? (value - .000001) : value;
  let valueIndex = Math.floor(value / 2);
    valueIndex =  ( valueIndex < 4 ) ? valueIndex : 4;
    if (valueIndex >= 0) {
        let data = _getDefinition(value, source);
        return data ? data[key] : null
    }
    return null;
}

export const getParameterBg = (value, definitionStyles, serialIndex = null) => {
    let minRange = [0, 2, 4, 6, 8];
    let valueIndex = _prepareValueIndex(value);
    if(!serialIndex) {
        return definitionStyles[valueIndex]?.bg || '#C9C9C9';
    }
    if (minRange[serialIndex] < value) {
      return definitionStyles[valueIndex].bg;
    }
    return "#C9C9C9";
}

export const getParameterStyleWithoutIndex = (value, definitionStyles) => {
    let valueIndex = _prepareValueIndex(value);
    if (definitionStyles[valueIndex]) {
        return definitionStyles[valueIndex];
    }
    return "#C9C9C9";
}

  export const getParameterDefinition = (value, definitionStyles) => {
    let valueIndex = _prepareValueIndex(value);
    if(definitionStyles[valueIndex]) {
      return definitionStyles[valueIndex].definition;
    }
    return null
  }

  export const getParameterDefinitionColor = (value, definitionStyles) => {
    let valueIndex = _prepareValueIndex(value);
    if(definitionStyles[valueIndex]) {
      return definitionStyles[valueIndex].bg;
    }
    return null
  }

  const _prepareValueIndex = (value) => {
    value = value > 0 ? (value - .000001) : value;
    let valueIndex = Math.floor(value / 2);
    return ( valueIndex < 4 ) ? valueIndex : 4;
  }

  export const getPropertyCollection = (module, parameter) => {
    let properties = module.properties.find(item => item.id === parameter.property_id)
      if(properties) {
        return properties.property_collections.filter(param => !param.option || param.option == 1)
      }
      return []
  }
export default class Parameter {
  getParameterValue = (...arg) => getParameterValue(...arg)
  getParameterDefinitionColor = (...arg) => getParameterDefinitionColor(...arg)
  getParameterDefinition = (...arg) => getParameterDefinition(...arg)
}
